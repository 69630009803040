<template>
  <v-container>
    <BaseScreenHeader
      title="Supplements Log"
      screenInfo="NTR-004"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <v-card-text v-if="loaded && supplements.length == 0" class="mt-4">
      <v-icon class="ml-n1">
        mdi-information-outline
      </v-icon>
      Logged supplements will be shown here. To log your supplements, please use
      the energy screen, accesible from the buttom menu.
    </v-card-text>
    <template v-if="supplements.length > 0">
      <BaseLoading v-if="!loaded" />
      <v-sheet class="charcoal pt-4">
        <v-card-actions
          :class="
            textSizeLarge + ' charcoal px-0 pb-2 charcoal--text text--lighten-3'
          "
        >
          <v-spacer />
          Daily Supplements
          <v-spacer />
        </v-card-actions>
        <v-timeline
          class="pt-2"
          v-if="supplements.length > 0"
          align-top
          :dense="isPhone"
        >
          <v-timeline-item
            color="paper"
            fill-dot
            :left="index % 2 == 0"
            :right="index % 2 != 0"
            v-for="(item, index) in supplements"
            :key="item.id"
            :class="isPhone ? 'ml-n7' : ''"
          >
            <template v-slot:icon>
              <v-icon :color="item.diff > 0 ? 'orange' : 'charcoal'">
                mdi-clipboard-text-clock
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span class="caption silver--text">
                {{ prettyShortDate(item.when) }}
              </span>
            </template>
            <v-card
              class="charcoal pt-2 link px-1 ml-n2"
              :style="charcoalTile"
              rounded
              elevation="2"
            >
              <v-card-actions :class="textSizeSmall + ' paper--text py-0'">
                <span title="When">
                  {{ prettyShortDate(item.when) }}
                </span>
                <v-spacer />
              </v-card-actions>
              <v-list class="transparent ">
                <v-list-item
                  v-for="(supplement, idx) in item.daily"
                  :key="idx"
                  class="paper--text py-0 mb-2 pl-2 charcoalTile"
                >
                  <v-list-item-content class="pb-0">
                    <v-card-actions class="pa-0">
                      {{ supplement.supplement }}
                      <v-spacer />
                      <v-icon
                        small
                        color="silver"
                        class=""
                        @click="deleteSupplement(supplement.id)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </v-card-actions>
                    <v-card-actions class="pa-0">
                      <span class="caption silver--text">
                        {{ prettyTime(supplement.full_date) }}
                      </span>
                      <v-spacer />
                      <span class="silver--text caption">
                        {{ supplement.qty }} {{ supplement.unit }}
                      </span>
                    </v-card-actions>
                    <v-card-actions v-if="supplement.rda > 0" class="pa-0">
                      <v-spacer />
                      <span class="silver--text caption">
                        RDA / AI {{ supplement.rda }} {{ supplement.unit }}
                      </span>
                    </v-card-actions>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </template>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'

export default {
  components: {},
  name: 'SupplementsLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      refresh: false,
      metric: false,
      showAddMealDialog: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      offset: 0,
      loadedList: [],
      datetime: null,
      supplements: [],
      selectedDateTime: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false
    }
  },
  beforeMount() {
    this.loadSupplements()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom && this.loaded) {
        this.currentPage++
        this.loadSupplements()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.supplements.length
    },
    ...appConfig
  },
  methods: {
    deleteSupplement(id) {
      return axios
        .delete(this.baseURL + '/nutrition/supplement/' + id)
        .then(response => {
          if (response.status == 200) {
            this.refreshPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    refreshPage() {
      this.loaded = false
      this.refresh = true
      this.loadSupplements()
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    setResults(value) {
      this.energyList = value
    },
    saved() {
      this.refresh = true
      this.currentPage = 0
      this.loadSupplements()
      this.showAddMealDialog = false
    },

    loadSupplements() {
      if (this.refresh) this.currentPage = 0
      return axios
        .get(
          this.baseURL +
            '/nutrition/supplementslog/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (this.refresh) {
              this.supplements = response.data.data
            } else
              this.supplements = this.supplements.concat(response.data.data)
            this.supplements.forEach(s => {
              s.daily = JSON.parse(s.supplements)
            })

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
