<template>
  <v-container>
    <BaseScreenHeader
      title="Food Log"
      screenInfo="NTR-001"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <v-card-text v-if="loaded && meals.length == 0" class="mt-4">
      <v-icon class="ml-n1">
        mdi-information-outline
      </v-icon>
      Logged meals will be shown here. To log your meals, please use the energy
      screen, accesible from the buttom menu.
    </v-card-text>
    <template v-if="meals.length > 0">
      <BaseLoading v-if="!loaded" />
      <v-sheet
        class="charcoal silver--text py-2 mt-2 px-6 "
        :style="charcoalTileMenu"
        v-if="stats"
      >
        <v-card-actions
          :class="textSizeLarge + '  px-0 pb- charcoal--text text--lighten-3'"
        >
          <v-spacer />
          30 Day Average
          <v-spacer />
        </v-card-actions>
        <v-sheet rounded class="transparent">
          <v-card-actions :class="textSizeXLarge + ' paper--text pa-0'">
            <v-spacer />
            {{ stats.calories }} kCal
            <v-spacer />
          </v-card-actions>
        </v-sheet>
        <v-sheet rounded class="transparent ">
          <v-card-actions :class="textSize + ' pa-0 mt-n1 mb-0'">
            <v-spacer />
            <span
              :class="stats.diff > 0 ? 'orange--text' : 'progressActive--text'"
            >
              {{ stats.diff > 0 ? 'Avg. Excess: ' : 'Avg. Deficit: ' }}
            </span>

            <span
              :class="stats.diff > 0 ? 'orange--text' : 'progressActive--text'"
            >
              <span class="pl-1">{{ Math.abs(stats.diff) }} kCal / day</span>
            </span>
            <v-spacer />
          </v-card-actions>
          <v-card-actions :class="textSizeSmall + ' pa-0 mt-n1 mb-2'">
            <v-spacer />
            <span class="silver--text">
              {{ stats.diff > 0 ? 'Avg. Weight Gain: ' : 'Avg. Weight Loss: ' }}
            </span>
            <span class="silver--text">
              <span class="pl-1">
                {{
                  Math.round((100 * (Math.abs(stats.diff) * 7)) / 3500) / 100
                }}
                lbs / week
              </span>
            </span>
            <v-spacer />
          </v-card-actions>
        </v-sheet>
        <v-row class="mt-2" justify="center">
          <v-col cols="4" align="center">
            <v-progress-circular
              :rotate="360"
              :width="isPhone ? 5 : 10"
              :size="isPhone ? 90 : 150"
              background-color="charcoal lighten-2"
              color="hybrid lighten-3"
              class="mr-1"
              :value="stats.percentage_fats"
            >
              <span :class="textSizeXSmall">
                FATS
                <v-card-actions class="pa-0">
                  <v-spacer />
                  {{ stats.percentage_fats }} %
                  <v-spacer />
                </v-card-actions>
              </span>
            </v-progress-circular>
          </v-col>
          <v-col cols="4" align="center">
            <v-progress-circular
              :rotate="360"
              :width="isPhone ? 5 : 10"
              :size="isPhone ? 90 : 150"
              background-color="charcoal lighten-2"
              color="gold"
              class="mx-1"
              :value="stats.percentage_carbs"
            >
              <span :class="textSizeXSmall">
                CARBS
                <v-card-actions class="pa-0">
                  <v-spacer />
                  {{ stats.percentage_carbs }} %
                  <v-spacer />
                </v-card-actions>
              </span>
            </v-progress-circular>
          </v-col>
          <v-col cols="4" align="center">
            <v-progress-circular
              :rotate="360"
              :width="isPhone ? 5 : 10"
              :size="isPhone ? 90 : 150"
              background-color="charcoal lighten-2"
              color="progressActive"
              class="ml-1"
              :value="stats.percentage_proteins"
            >
              <span :class="textSizeXSmall">
                PROTEINS
                <v-card-actions class="pa-0">
                  <v-spacer />
                  {{ stats.percentage_proteins }} %
                  <v-spacer />
                </v-card-actions>
              </span>
            </v-progress-circular>
          </v-col>
        </v-row>
        <v-row class="my-0" justify="center">
          <v-col cols="4" align="center">
            <span class="hybrid--text text--lighten-3">
              {{ Math.round(stats.fats) }} grams
            </span>
          </v-col>
          <v-col cols="4" align="center">
            <span class="gold--text">
              {{ Math.round(stats.carbs) }} grams
            </span>
          </v-col>
          <v-col cols="4" align="center" justify="center">
            <span class="progressActive--text">
              {{ Math.round(stats.proteins) }} grams
            </span>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet class="charcoal pt-4">
        <v-card-actions
          :class="
            textSizeLarge + ' charcoal px-0 pb- charcoal--text text--lighten-3'
          "
        >
          <v-spacer />
          Day To Day
          <v-spacer />
        </v-card-actions>
        <v-timeline
          class="pt-0"
          v-if="meals.length > 0"
          align-top
          :dense="isPhone"
        >
          <v-timeline-item
            color="paper"
            fill-dot
            :left="index % 2 == 0"
            :right="index % 2 != 0"
            v-for="(item, index) in meals"
            :key="item.id"
            :class="isPhone ? 'ml-n7' : ''"
          >
            <template v-slot:icon>
              <v-icon :color="item.diff > 0 ? 'orange' : 'charcoal'">
                mdi-clipboard-text-clock
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span class="caption silver--text">
                {{ prettyShortDate(item.when) }}
              </span>
            </template>
            <v-card
              class="charcoal py-2 link px-1 ml-n2"
              :style="charcoalTile"
              rounded
              elevation="2"
            >
              <v-card-actions :class="textSizeSmall + ' paper--text py-0'">
                <span title="When">
                  {{ prettyShortDate(item.when) }}
                </span>
                <v-spacer />
                <span
                  :class="
                    item.diff <= 0 ? ' progressActive--text' : ' orange--text'
                  "
                >
                  {{ item.diff > 0 ? 'Excess' : 'Deficit' }}
                </span>
              </v-card-actions>
              <v-card-actions :class="textSize + ' pt-0 paper--text'">
                <v-spacer />
                <span
                  :class="
                    item.diff <= 0 ? ' progressActive--text' : ' orange--text'
                  "
                >
                  {{ Math.abs(item.diff) }} kCal
                </span>
              </v-card-actions>
              <BasicNutrition :item="item" />
              <v-btn
                large
                title="View Details"
                block
                plain
                :style="charcoalTile"
                class="mt-2 charcoal paper-text"
                color="paper"
                @click="$router.push(/foodlog/ + item.when)"
              >
                View Details
              </v-btn>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </template>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'
const BasicNutrition = () => import('@/components/blocks/BasicNutrition.vue')
export default {
  components: { BasicNutrition },
  name: 'NutritionLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      refresh: false,
      metric: false,
      showAddMealDialog: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      offset: 0,
      loadedList: [],
      datetime: null,
      meals: [],
      stats: null,
      selectedDateTime: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false
    }
  },
  beforeMount() {
    this.loadMeals()
    this.loadStats()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom && this.loaded) {
        this.currentPage++
        this.loadMeals()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.meals.length
    },
    ...appConfig
  },
  methods: {
    refreshPage() {
      this.loaded = false
      this.refresh = true
      this.loadStats()
      this.loadMeals()
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    setResults(value) {
      this.energyList = value
    },
    saved() {
      this.refresh = true
      this.currentPage = 0
      this.loadMeals()
      this.showAddMealDialog = false
    },
    loadStats() {
      return axios
        .get(this.baseURL + '/nutrition/foodlog/avg', {})
        .then(response => {
          if (response.status == 200) {
            this.stats = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMeals() {
      if (this.refresh) this.currentPage = 0
      return axios
        .get(
          this.baseURL +
            '/nutrition/foodlog/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (this.refresh) {
              this.meals = response.data.data
            } else this.meals = this.meals.concat(response.data.data)

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
