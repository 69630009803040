<template>
  <v-container>
    <BaseScreenHeader
      title="My Pantry"
      screenInfo="NTR-003"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />
    <BaseLoading class="mt-2" v-if="!loaded" />
    <v-card-actions class="pa-0 mt-4 charcoal">
      <v-spacer />
      <v-icon
        v-if="scrollPosition <= 50 && loaded"
        large
        color="paper"
        class="addBtn"
        @click="showAddMealDialog = true"
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-card-actions>
    <v-sheet
      rounded
      class="transparent silver--text pa-2 charcoalTile"
      v-if="loaded && favoritesList.length == 0"
    >
      Your pantry is empty! You should add meals/food that you consume often to
      the pantry.
    </v-sheet>
    <template>
      <v-sheet class="charcoal pt-4 mt-4" v-if="favoritesList.length > 0">
        <v-card-actions class="pt-2 mb-2">
          <BaseSearch
            dark
            :items="favoritesList"
            :label="'pantry (' + favoritesList.length + ')'"
            what="name"
            @search_results="setResults"
          />
        </v-card-actions>
        <v-timeline align-top :dense="isPhone" dark>
          <v-timeline-item
            color="charcoal"
            fill-dot
            dark
            :left="index % 2 == 0"
            :right="index % 2 != 0"
            v-for="(item, index) in favoritesList"
            :key="index"
            :class="isPhone ? 'ml-n6 ' : ''"
          >
            <template v-slot:icon>
              <v-checkbox
                dark
                color="accent"
                class=" mt-5 ml-2"
                on-icon="mdi-heart"
                true-value="Yes"
                false-value="No"
                off-icon="mdi-heart-outline"
                v-model="item.favorite"
                label=""
                @change="saveFavorite(item)"
              />
            </template>
            <template v-slot:opposite>
              <span class="caption silver--text">
                {{ prettyDate(item.created) }}
              </span>
            </template>
            <v-card
              class="charcoal charcoalTileMenu py-2 link px-2 ml-n2"
              rounded
              elevation="1"
            >
              <v-card-actions
                v-if="item.name != 'NA'"
                :class="textSizeXSmall + ' silver--text pa-0'"
              >
                <v-card-text :class="textSize + ' paper--text pa-0'">
                  <span title="Food/Meal Name">
                    {{ item.name }}
                  </span>
                </v-card-text>
                <v-spacer />
              </v-card-actions>
              <v-card-actions :class="textSizeXSmall + ' silver--text pa-0'">
                <span title="Quantity"> {{ item.qty }} {{ item.unit }} </span>
                <v-spacer />
                <v-icon
                  small
                  class="ml-n2"
                  color="silver"
                  @click="deleteFavorite(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-card-actions>
              <v-sheet rounded class="transparent">
                <BasicNutrition :item="item" />
              </v-sheet>
              <v-sheet rounded class="transparent mt-2">
                <v-card-actions class="pa-0">
                  <v-btn
                    class="transparent ml-n2"
                    v-if="item.ingredients != null"
                    color="paper"
                    plain
                    @click="showIngredients(item)"
                  >
                    <v-icon color="silver">
                      mdi-link-box-variant
                    </v-icon>
                    INGREDIENTS
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    class="transparent"
                    color="paper"
                    plain
                    @click="showNutrients(item)"
                  >
                    <v-icon color="silver">
                      mdi-link-box
                    </v-icon>
                    NUTRIENTS
                  </v-btn>
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </template>
    <AddMealDialog
      :pantryMode="true"
      :show="showAddMealDialog"
      @done="doneAdding"
      @cancel="showAddMealDialog = false"
    />
    <AddRecipeDialog
      :show="showAddRecipeDialog"
      @done="doneAdding"
      @cancel="showAddRecipeDialog = false"
    />
    <ViewNutrients
      :show="showNutrientsDialog"
      :nutrients="nutrients"
      @cancel="showNutrientsDialog = false"
      @done="showNutrientsDialog = false"
    />
    <ViewIngredients
      :show="showIngredientsDialog"
      :pantryItem="nutrients"
      @cancel="showIngredientsDialog = false"
      @done="ingredientsUpdated"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const AddMealDialog = () => import('@/components/dialogs/AddMeal.vue')
const AddRecipeDialog = () => import('@/components/dialogs/AddRecipe.vue')
const BasicNutrition = () => import('@/components/blocks/BasicNutrition.vue')
const ViewNutrients = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ViewNutrients.vue')
const ViewIngredients = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ViewIngredients.vue')

import debounce from 'lodash/debounce'
export default {
  components: {
    AddRecipeDialog,
    BasicNutrition,
    AddMealDialog,
    ViewNutrients,
    ViewIngredients
  },
  name: 'MyPantry',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      showNutrientsDialog: false,
      showIngredientsDialog: false,
      scrollPosition: 0,
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      refresh: false,
      metric: false,
      showAddMealDialog: false,
      showAddRecipeDialog: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      itemName: '',
      favoritesList: [],
      loadedList: [],
      datetime: null,
      nutrients: {},
      selectedDateTime: null,
      debounce: null,
      pagesize: 150,
      currentPage: 0,
      bottom: false
    }
  },
  beforeMount() {
    this.loadFavorites()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
    this.scrollToTop()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom && this.loaded) {
        this.currentPage++
        this.loadFavorites()
      }
    }
  },
  computed: {
    ...appConfig,
    itemsCount() {
      return this.favoritesList.length
    },
    distanceLabel() {
      if (this.metric) return 'Distance (km)'
      else return 'Distance (miles)'
    }
  },
  methods: {
    ingredientsUpdated() {
      this.refreshPage()
      this.showIngredientsDialog = false
    },
    showNutrients(item) {
      this.nutrients = item
      this.showNutrientsDialog = true
    },
    showIngredients(item) {
      this.nutrients = item
      this.showIngredientsDialog = true
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.scrollPosition =
        window.pageYOffset ||
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      this.bottom = this.bottomVisible()
    },
    doneAdding() {
      this.showAddMealDialog = false
      this.showAddRecipeDialog = false
      this.loadFavorites()
    },
    refreshPage() {
      this.loaded = false
      this.refresh = true
      this.loadFavorites()
    },

    setResults(value) {
      this.favoritesList = value
    },
    saved() {
      this.refresh = true
      this.currentPage = 0
      this.loadFavorites()
      this.showAddMealDialog = false
    },
    addActivity() {
      this.showAddDialog = true
    },
    cancel() {
      this.showAddDialog = false
    },
    saveFavorite(item) {
      return axios
        .post(this.baseURL + '/nutrition/favorite/toggle', { item: item })
        .then(response => {
          if (response.status == 200) {
            this.loadFavorites()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteFavorite(item) {
      return axios
        .get(this.baseURL + '/nutrition/favorite/delete/' + item.id, {})
        .then(response => {
          if (response.status == 200) {
            this.loadFavorites()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadFavorites() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/nutrition/favorites', {})
        .then(response => {
          if (response.status == 200) {
            this.favoritesList = response.data.data
            this.favoritesNum = this.favoritesList.length
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

<style scoped>
.addBtn {
  position: fixed;
  right: 5px;
  top: 75px;
  z-index: 100;
}
</style>
