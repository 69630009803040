<template>
  <span>
    <BaseScreenHeader
      title="Energy Log"
      screenInfo="NRG-001"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <v-card-text v-if="loaded && energyList.length == 0" class="mt-4">
      <v-icon class="ml-n1">
        mdi-information-outline
      </v-icon>
      Logged meals and activities will be summarized here for each day. To log
      meals and activities, please use the energy screen, accesible from the
      buttom menu.
    </v-card-text>
    <template>
      <BaseLoading v-if="!loaded" />
      <v-sheet class="charcoal mt-5">
        <v-timeline v-if="energyList.length > 0" align-top :dense="isPhone">
          <v-timeline-item
            :color="item.diff > 0 ? 'silver' : 'progressActive'"
            fill-dot
            :left="index % 2 == 0"
            :right="index % 2 != 0"
            v-for="(item, index) in energyList"
            :key="item.id"
            :class="isPhone ? 'ml-n6' : ''"
          >
            <template v-slot:icon>
              <v-icon color="charcoal">
                {{
                  parseInt(item.diff) > 0
                    ? 'mdi-battery-plus'
                    : 'mdi-battery-minus'
                }}
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span class="caption paper--text">
                {{ prettyShortDate(item.when) }}
              </span>
            </template>
            <v-card
              class="charcoal charcoalTileMenu py-2 rounded-lg rounded-t-0 link ml-n2"
              rounded
            >
              <v-card-actions :class="textSizeSmall + ' paper--text py-0'">
                <span title="When">
                  {{ prettyShortDate(item.when) }}
                </span>
                <v-spacer />
                <span
                  :class="
                    item.diff <= 0 ? ' progressActive--text' : ' orange--text'
                  "
                >
                  {{ item.diff > 0 ? 'Excess' : 'Deficit' }}
                </span>
              </v-card-actions>
              <v-card-actions :class="textSizeLarge + ' pt-0 paper--text'">
                <v-spacer />
                <span
                  :class="
                    item.diff <= 0 ? ' progressActive--text' : ' orange--text'
                  "
                >
                  {{ Math.abs(item.diff) }} kCal
                </span>
              </v-card-actions>
              <v-sheet rounded class="transparent pb-4 px-1 ">
                <v-card-actions class="silver--text py-0">
                  In
                  <v-spacer />
                  {{ item.calories }} kCal
                </v-card-actions>
                <v-card-actions class="silver--text py-0">
                  Out
                  <v-spacer />
                  TDEE {{ item.tdee }} + {{ item.calories_out }} kCal
                </v-card-actions>
              </v-sheet>
              <v-card-actions class="pa-0">
                <v-spacer />
                <v-progress-circular
                  :rotate="360"
                  :width="5"
                  :size="85"
                  background-color="charcoal lighten-2"
                  color="hybrid lighten-3"
                  class="mr-1"
                  :value="item.percentage_fats"
                >
                  <span class="caption">
                    Fats %
                    <v-card-actions class="pa-0">
                      <v-spacer />
                      {{ item.percentage_fats }}
                      <v-spacer />
                    </v-card-actions>
                  </span>
                </v-progress-circular>
                <v-progress-circular
                  :rotate="360"
                  :width="5"
                  :size="85"
                  background-color="charcoal lighten-2"
                  color="gold"
                  class="mx-1"
                  :value="item.percentage_carbs"
                >
                  <span class="caption">
                    Carbs %
                    <v-card-actions class="pa-0">
                      <v-spacer />
                      {{ item.percentage_carbs }}
                      <v-spacer />
                    </v-card-actions>
                  </span>
                </v-progress-circular>
                <v-progress-circular
                  :rotate="360"
                  :width="5"
                  :size="85"
                  background-color="charcoal lighten-2"
                  color="progressActive"
                  class="ml-1"
                  :value="item.percentage_proteins"
                >
                  <span class="caption">
                    Proteins %
                    <v-card-actions class="pa-0">
                      <v-spacer />
                      {{ item.percentage_proteins }}
                      <v-spacer />
                    </v-card-actions>
                  </span>
                </v-progress-circular>
                <v-spacer />
              </v-card-actions>
              <template v-if="item.calories_per_minute > 0">
                <v-card-title
                  :class="
                    textSizeXSmall + ' purpleAccent--text pl-2 pr-0  pb-0'
                  "
                >
                  <span class="success--text">
                    Movement
                  </span>
                  <v-spacer />
                  <span title="Category" class="success--text pr-2">
                    {{ item.duration }} minutes @{{ item.calories_per_minute }}
                    kCal/minute
                  </span>
                </v-card-title>
                <v-card-actions
                  v-if="item.distance_imperial > 0"
                  :class="textSizeXSmall + ' pa-0'"
                >
                  <v-spacer />
                  <span title="Distance" class="success--text pr-2">
                    {{ item.distance_imperial }} miles or
                    {{ item.distance_metric }} Km
                  </span>
                </v-card-actions>
                <v-card-actions
                  :class="textSizeXSmall + ' success--text pl-2 pr-0 py-0'"
                >
                  Activity Calories
                  <v-spacer />
                  <span title="Calories Burned" class="pr-2">
                    {{ item.calories_out }} kCal
                  </span>
                </v-card-actions>
              </template>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </template>
    <Activities
      :show="showAddMealDialog"
      @cancel="showAddMealDialog = false"
      @saved="saved"
    />
  </span>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const Activities = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Activities.vue')
import debounce from 'lodash/debounce'
export default {
  components: { Activities },
  name: 'NutritionLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      refresh: false,
      metric: false,
      showAddMealDialog: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      energyList: [],
      datetime: null,
      selectedDateTime: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false
    }
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom && this.loaded) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.energyList.length
    },
    distanceLabel() {
      if (this.metric) return 'Distance (km)'
      else return 'Distance (miles)'
    },
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !(this.imperial > 0 || this.metric > 0) &&
        errors.push('Value is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    refreshPage() {
      this.loaded = false
      this.refresh = true
      this.loadPage()
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    setResults(value) {
      this.energyList = value
    },
    saved() {
      this.refresh = true
      this.currentPage = 0
      this.loadPage()
      this.showAddMealDialog = false
    },
    addActivity() {
      this.showAddDialog = true
    },
    cancel() {
      this.showAddDialog = false
    },

    loadPage() {
      this.loaded = false
      if (this.refresh) this.currentPage = 0
      return axios
        .get(
          this.baseURL +
            '/nutrition/dailyenergy/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (this.refresh) {
              this.energyList = response.data.data
            } else this.energyList = this.energyList.concat(response.data.data)
            this.loaded = true
            this.refresh = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
