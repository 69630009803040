<template>
  <span>
    <BaseScreenHeader
      title="Food Log Details"
      screenInfo="NTR-002"
      :showBack="true"
    />
    <FoodDetails :day="day" />
  </span>
</template>
<script>
import util from '@/mixins/util.js'
const FoodDetails = () => import('@/components/pageutil/FoodDetails.vue')
export default {
  components: { FoodDetails },
  name: 'FoodLogDetails',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    day: {
      type: String
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      refresh: false,
      metric: false,
      showAddMealDialog: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      offset: 0,
      loadedList: [],
      datetime: null,
      meals: [],
      selectedDateTime: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false
    }
  },
  beforeMount() {},
  computed: {},
  methods: {}
}
</script>
